var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = container.invokePartial(require("./partials/html-head.hbs"),depth0,{"name":"./partials/html-head","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n  <body class=\"site site-"
    + alias3(alias2((depth0 != null ? depth0.pageKey : depth0), depth0))
    + "\">\r\n"
    + ((stack1 = container.invokePartial(require("./partials/site-header.hbs"),depth0,{"name":"./partials/site-header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n    <main class=\"main main-"
    + alias3(alias2((depth0 != null ? depth0.pageKey : depth0), depth0))
    + "\">\r\n"
    + ((stack1 = container.invokePartial(require("./partials/hero.hbs"),depth0,{"name":"./partials/hero","hash":{"isDetail":false},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/about.hbs"),depth0,{"name":"./partials/about","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../utils/hbs-helpers/globals.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"globals","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":14,"column":18}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/site-footer.hbs"),depth0,{"name":"./partials/site-footer","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </main>\r\n    \r\n"
    + ((stack1 = container.invokePartial(require("./partials/footer.hbs"),depth0,{"name":"./partials/footer","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </body>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/blog/blog-latest.hbs"),depth0,{"name":"./partials/blog/blog-latest","hash":{"blogEntries":(depth0 != null ? depth0.blog : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<!DOCTYPE html>\r\n<html lang=\"en\">\r\n"
    + ((stack1 = __default(require("../../utils/hbs-helpers/getData.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.pageKey : depth0),{"name":"getData","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":20,"column":12}}})) != null ? stack1 : "")
    + "\r\n</html>";
},"usePartial":true,"useData":true});