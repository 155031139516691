
import Masonry from 'masonry-layout';

const toggle = '[data-masonry="grid"]';

class CustomMasonry {
  static initClass() {
  
    CustomMasonry.VERSION = '1.0.0';
  
    CustomMasonry.DEFAULTS = {
      cols: 3,
      gutter: 50,
      itemSelector: '[data-masonry="item"]',
      toggle,
    };
  }

  constructor(element, options) {
    this.element = $(element);

    this.data = this.element.data();
    this.options = $.extend({}, this.DEFAULTS, options, this.data);

    const items = $(this.options.itemSelector);
    // const columnWidth = (this.element.innerWidth() / this.options.cols) - (this.options.gutter * this.options.cols);

    if (items && items.length > 1) {
      new Masonry( this.element[0], {
        // columnWidth,
        itemSelector: this.options.itemSelector,
        percentPosition: true,
        horizontalOrder: true,
      });
    }
  }
}

CustomMasonry.initClass();

const Plugin = function() {
  const method = typeof arguments[0] === 'string' ? arguments[0] : 'set';
  const option = typeof arguments[1] === 'object' ? arguments[1] : {};
  return this.each(function() {
    const $this = $(this);
    if (method) {
      $(this).data('method', method);
    }
    const data = $(this).data();
    const options = $.extend({}, CustomMasonry.DEFAULTS, data, option);
    new CustomMasonry($this, options);
  });
};

const old = $.fn.customMasonry;
$.fn.customMasonry = Plugin;
$.fn.customMasonry.Constructor = CustomMasonry;
$.fn.customMasonry.noConflict = function() {
  $.fn.customMasonry = old;
  return this;
};

$(document).ready(() => {
  $(toggle).each(function() {
    $(this).customMasonry();
  });
});