import 'bootstrap/js/src';

import './modules/masonry';
import './modules/push-nav';
import './modules/random-content';
import './modules/set-scrollbar-width';
import './modules/sticky';
import './modules/tab';
import './modules/transition';
import './modules/way-points';
import './modules/change-on-scroll';

const switchSiteHeaderColor = () => {
  const element = document.querySelector('header.site-header');
  if (!element) {
    return;
  }

  const sections = [
    document.querySelector('section.hero'),
    document.querySelector('section.site-footer')
  ];

  const hasClass = (node, className) => 
    node && node.classList.contains(className) ? true : false;

  const getData = (node) => node.dataset || undefined;

  const activateWhiteColor = () => {
    const data = getData(element);
    if (
      ((data && data.isWhite === 'true') || (data && data.isWhite)) && 
      !hasClass(element, 'site-header--white')
    ) {
      element.classList.add('site-header--white');
    }
  };

  const deactivateWhiteColor = () => {
    if (hasClass(element, 'site-header--white')) {
      element.classList.remove('site-header--white');
    }
  };

  const forEachEvent = (target, events) => {    
    if (!events || (events && events.length === 0)) {
      return;
    }
    events.forEach(event => target.addEventListener(event.name, event.callBack));
  };

  sections.forEach(section => {
    if (!section) {
      return;
    }

    const eventsArray = [{
      name: 'enter.scrolling-up.waypoint',
      callBack: () => activateWhiteColor(),
    }, {
      name: 'out-of-view.scrolling-down.waypoint',
      callBack: () => deactivateWhiteColor(),
    }, {
      name: 'in-view.scrolling-down.waypoint',
      callBack: () => activateWhiteColor(),
    }, {
      name: 'exit.scrolling-up.waypoint',
      callBack: () => deactivateWhiteColor(),
    }];

    forEachEvent(section, eventsArray);
  });
};

$(document).ready(() => switchSiteHeaderColor());

// let scrolled = false;
// let scrollY = 0;
// let hide = false;

const hideNavOnScrollDown = () => {

  let scrollY = 0;
  let isHidden = false;
  let scrolled = false;

  // let interval;
  
  const headerElement = document.querySelector('header.site-header')

  const handleScroll = () => {
    scrolled = window.scrollY !== scrollY;
    if (scrolled) {
      isHidden = scrollY < window.scrollY;
      scrollY = window.scrollY;
    }

    if (headerElement && window.scrollY > 0) {
      if (headerElement.classList.value.indexOf('site-header--scrolled') === -1) {
        headerElement.classList.add('site-header--scrolled');
      }
    } else {
      if (headerElement.classList.value.indexOf('site-header--scrolled') > -1) {
        headerElement.classList.remove('site-header--scrolled');
      }
    }
  };

  handleScroll();

  // interval = window.setInterval(handleScroll, 500);

  window.addEventListener('scroll', handleScroll);
};

hideNavOnScrollDown();



// const hasScrolled = () => {
//   scrolled = window.scrollY !== scrollY;
  
//   hide = scrollY < window.scrollY;
//   console.log(hide);
  
//   if (scrolled) {
//     scrollY = window.scrollY;

//     // console.log(window.scrollY);
//   }
// };
// hasScrolled();

// window.addEventListener('scroll', ev => {
//   hasScrolled();
// });

