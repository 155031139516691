
import 'waypoints/lib/noframework.waypoints';
import 'waypoints/lib/shortcuts/inview'; 

export const wayPoint = (element, options) => {
  const attachEvent = (element, name, data = {}) => {
    const event = new CustomEvent(name, data);
    element.dispatchEvent(event);
  };

  if ('string' === typeof element) {
    element = document.querySelector(element);
  }
  
  if (!element) {
    throw new Error('Couldn\'t find way point element');
  }

  options = Object.assign({}, options, element.dataset);

  const getOffset = () => {
    const offsetElement = document.querySelector(options.offsetElement);

    if (!offsetElement) {
      return options.offset;
    }

    return -((offsetElement.offsetHeight || 0) + (offsetElement.offsetTop || 0));
  };

  let initWayPoint;

  if (options.method && options.method === 'inView') {
    initWayPoint = () => new Waypoint.Inview({
      element,
      offset: getOffset(),
      enter: (direction) => 
        attachEvent(element, `enter.scrolling-${direction}.waypoint`),
      entered: (direction) => 
        attachEvent(element, `in-view.scrolling-${direction}.waypoint`),
      exit: (direction) => 
        attachEvent(element, `exit.scrolling-${direction}.waypoint`),
      exited: (direction) => 
        attachEvent(element, `out-of-view.scrolling-${direction}.waypoint`),
    });
  } else {
    initWayPoint = () => new Waypoint({
      element,
      offset: options.offset,
      handler: (direction) =>  
        attachEvent(element, `enter-${direction}.waypoint`),
    });
  }
  
  initWayPoint();

  // element.addEventListener('enter.scrolling-down.waypoint', (event) => {
  //   console.log(event);
  // });
  // element.addEventListener('in-view.scrolling-down.waypoint', (event) => {
  //   console.log(event);
  // });
  // element.addEventListener('out-of-view.scrolling-down.waypoint', (event) => {
  //   console.log(event);
  // });
  // element.addEventListener('exit.scrolling--down.waypoint', (event) => {
  //   console.log(event);
  // });
  // element.addEventListener('enter.scrolling-up.waypoint', (event) => {
  //   console.log(event);
  // });
  // element.addEventListener('in-view.scrolling-up.waypoint', (event) => {
  //   console.log(event);
  // });
  // element.addEventListener('out-of-view.scrolling-up.waypoint', (event) => {
  //   console.log(event);
  // });
  // element.addEventListener('exit.scrolling-up.waypoint', (event) => {
  //   console.log(event);
  // });
};

export const wayPoints = (trigger = '[data-trigger="wayPoint"]', options = {}) => {
  const elements = document.querySelectorAll(trigger);
  const defaults = Object.assign({
    method: 'inView'
  }, options);
  
  if (elements && elements.length > 0) {
    elements.forEach(element => wayPoint(element, defaults));
  }
};

$(document).ready(() => wayPoints());