var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nav : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":111,"column":20},"end":{"line":132,"column":29}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "                      <li class=\"nav__item\">\r\n                        <a class=\"nav__link\" href=\""
    + alias3(alias2(((stack1 = blockParams[0][0]) != null ? stack1.href : stack1), depth0))
    + "\" title=\""
    + alias3(alias2(((stack1 = blockParams[0][0]) != null ? stack1.title : stack1), depth0))
    + "\">\r\n                          <span class=\"nav__link--small\">"
    + alias3(alias2(((stack1 = blockParams[0][0]) != null ? stack1.small : stack1), depth0))
    + "</span>\r\n                          <span class=\"nav__text\">\r\n                            "
    + alias3(alias2((depth0 != null ? depth0.pageKey : depth0), depth0))
    + "\r\n                            "
    + alias3(alias2(((stack1 = blockParams[0][0]) != null ? stack1.text : stack1), depth0))
    + "\r\n                          </span>\r\n                        </a>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? stack1.sub : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":120,"column":24},"end":{"line":130,"column":31}}})) != null ? stack1 : "")
    + "                      </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                          <ul class=\"nav nav-sub\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.sub : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":28},"end":{"line":128,"column":37}}})) != null ? stack1 : "")
    + "                          </ul>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "                              <li class=\"nav-sub__item\">\r\n                                <a class=\"nav-sub__link\" href=\""
    + alias3(alias2((depth0 != null ? depth0.href : depth0), depth0))
    + "\" title=\""
    + alias3(alias2((depth0 != null ? depth0.title : depth0), depth0))
    + "\">\r\n                                  "
    + alias3(alias2((depth0 != null ? depth0.text : depth0), depth0))
    + "\r\n                                </a>\r\n                              </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "\r\n<header class=\"site-header\" data-trigger-push=\"nav\">\r\n  <div class=\"grid--fluid\">\r\n    <div class=\"grid__row--no-gutters\">\r\n      <div class=\"grid__col--3\">\r\n        <div class=\"site-header__logo\">\r\n          <div class=\"logo\" role=\"banner\">\r\n            <a class=\"logo__link\" href=\"/\" title=\"A fancy SEO title\">\r\n              <span data-scroll=\"init\" data-offset=\"#hero__image--wrap\" data-style='{\"transform\" : {\"translate\": {\"start\": [0, 0], \"end\": [-1500, 0], \"unit\": \"px\"}}}'>Team </span> <span>Endl</span><span>a</span><span>z</span><span>e</span><span>r</span>\r\n            </a>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"grid__col--9\">\r\n        <div class=\"site-header__navigation\">\r\n          <div class=\"navigation\">\r\n\r\n            <div class=\"navigation__button-wrap\">\r\n              <button \r\n                class=\"navigation__button\" \r\n                data-toggle=\"pushnav\" \r\n                data-target=\"#navigation_main\" \r\n                role=\"button\" \r\n                type=\"menu\"\r\n              >\r\n                <span class=\"navigation__button--open\">\r\n                  <span>M</span><span>e</span><span>n</span><span>u</span>\r\n                </span>\r\n                <span class=\"navigation__button--close\">\r\n                  <span>C</span><span>l</span><span>o</span><span>s</span><span>e</span>\r\n                </span>\r\n              </button>\r\n            </div>\r\n\r\n            <nav class=\"navigation__overlay push-nav\" id=\"navigation_main\" role=\"navigation\">\r\n              <div class=\"navigation__menu\">\r\n                <ul class=\"nav nav--header\" role=\"presentation\">\r\n"
    + ((stack1 = __default(require("../../../utils/hbs-helpers/globals.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"globals","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":110,"column":18},"end":{"line":133,"column":30}}})) != null ? stack1 : "")
    + "                </ul>\r\n              </div>\r\n            </nav>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</header>\r\n";
},"useData":true,"useBlockParams":true});