class SetScrollbarWidth {
  static initClass() {
  
    SetScrollbarWidth.VERSION = '1.0.0';
  
    SetScrollbarWidth.DEFAULTS = {
      toggle :           'setscrollbarwidth',
      eventName:         'setscrollbarwidth',
      css:               'padding-right',
      trigger:           null,
      ignoreHeight:      false,
      setOverflow:       true
    };
  }
  constructor(element, options) {
    this.element = $(element);
    this.data = this.element.data();
    this.options = $.extend({}, this.DEFAULTS, options, this.data);
    
    if (!this.options.trigger) {      
      if (($('html').height() <= $(window).height()) && (this.options.ignoreHeight === false)) { return; }


      this.outer = document.createElement('div');
      this.outer.style.visibility = 'hidden';
      this.outer.style.width = '100%';
      this.outer.style.msOverflowStyle = 'scrollbar';

      document.body.appendChild(this.outer);

      this.widthNoScroll = this.outer.offsetWidth;

      this.outer.style.overflow = 'scroll';
      this.inner = document.createElement('div');
      this.inner.style.width = '100%';
      this.outer.appendChild(this.inner);
      this.widthWithScroll = this.inner.offsetWidth;

      this.outer.parentNode.removeChild(this.outer);

      this.scrollbar = this.widthNoScroll - this.widthWithScroll;

    } else {
      this.trigger = $(this.options.trigger);
      this.child = this.trigger.children().first();
      this.outer = {
        width : this.trigger.width(),
        height: this.trigger.height()
      };
      this.inner = {
        width : this.child.outerWidth(),
        height: this.child.outerHeight()
      };

      if ((this.outer.height <= this.inner.height) && this.options.ignoreHeight) { return; }

      this.scrollbar = this.outer.width - this.inner.width;
    }

    if (this.options.method) {
      this[this.options.method]();
    }
  }

  set() {
    this.element.css(this.options.css, this.scrollbar);
    if (this.options.setOverflow) {
      return this.element.css('overflow', 'hidden');
    }
  }

  clear() {
    this.element.css(this.options.css, '');
    if (this.options.setOverflow) {
      return this.element.css('overflow', '');
    }
  }

  createEventListener(name) {
    const eventType = name;
    const event = $.Event(eventType + ':' + this.options.eventName);
    this.$element.trigger(event);
  }
}
SetScrollbarWidth.initClass();

const Plugin = function() {
  const method = typeof arguments[0] === 'string' ? arguments[0] : 'set';
  const option = typeof arguments[1] === 'object' ? arguments[1] : {};
  return this.each(function() {
    const $this = $(this);
    if (method) {
      $(this).data('method', method);
    }
    const data = $(this).data();
    const options = $.extend({}, SetScrollbarWidth.DEFAULTS, data, option);
    new SetScrollbarWidth($this, options);
  });
};

const old = $.fn.setscrollbarwidth;
$.fn.setscrollbarwidth = Plugin;
$.fn.setscrollbarwidth.Constructor = SetScrollbarWidth;
$.fn.setscrollbarwidth.noConflict = function() {
  $.fn.setscrollbarwidth = old;
  return this;
};
