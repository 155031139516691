const tabs = () => {
  const selectors = {
    button: '.tabs__nav-item > [role="button"]',
    content: (id) => `[data-id="${id}"]`,
    contents: '[data-tab="content"] > div',
    parent: '[data-toggle="tabs"]',
  };
  
  const isActive = (element) => {
    const data = element.dataset;
    if (data && data.active === 'true') {
      return true;
    }
    return false;
  };
  
  const attachEvent = (element, name, data = {}) => {
    const event = new CustomEvent(name, data);
    element.dispatchEvent(event);
  };
  
  const activateItem = (element, className) => {
    element.setAttribute('data-active', 'true');
    element.setAttribute('aria-hidden', 'false');
    element.classList.add(className);
    element.classList.add('opening');
    attachEvent(element, 'tabs.show');
    setTimeout(() => {
      element.classList.remove('opening');
      element.classList.add('open');
      attachEvent(element, 'tabs.shown');
    }, 300);
  };
  
  const deactivateItem = (elements, comparator, target, className) => {
    if (elements && elements.length > 0) {
      elements.forEach(element => {
        const data = element.dataset;
        
        if ((data[comparator] !== target) && isActive(element)) {
          element.setAttribute('aria-hidden', 'true');
          element.setAttribute('data-active', 'false');
          element.classList.remove(className);
          element.classList.remove('open');
          element.classList.add('closing');
          attachEvent(element, 'tabs.hide');
  
          setTimeout(() => {
            attachEvent(element, 'tabs.hidden');
            element.classList.remove('closing');
          }, 300);
        }
      });
    }
  };
  
  const parents = document.querySelectorAll(selectors.parent);

  if (parents && parents.length > 0) {
    parents.forEach(parent => {
  
      const buttons = parent.querySelectorAll(selectors.button);
      const items = parent.querySelectorAll(selectors.contents);
      
      if (buttons && buttons.length > 0) {
        const handleClick = (element, event) => {
          event.preventDefault();
  
            const data = element.dataset;
            const currentItem = data && data.target && parent.querySelector(selectors.content(data.target));
  
            if (!currentItem) {
              return;
            }
  
            const buttonIsActive = isActive(element);
            const contentIsActive = isActive(currentItem);
            
            if (!buttonIsActive) {
              activateItem(element, 'methods__header--active');
            }
  
            if (!contentIsActive) {
              activateItem(currentItem, 'methods__body--active')
            }
  
            // deactivate contents
            deactivateItem(items, 'id', data.target, 'methods__body--active');
            
            // deactivate element
            deactivateItem(buttons, 'target', data.target, 'methods__header--active');
        };
  
        buttons.forEach(button => 
          button.addEventListener('click', (event) => handleClick(button, event)));
  
        /* How to handle Events for tabs
          buttons.forEach(button => {
            button.addEventListener('tabs.show', (event) => console.log(event));
            button.addEventListener('tabs.shown', (event) => console.log(event));
            button.addEventListener('tabs.hide', (event) => console.log(event));
            button.addEventListener('tabs.hidden', (event) => console.log(event));
          });
        */
      }
    });
  }
};

$(document).ready(() => tabs());
