
/**
 * Function / Plugin to load random content inside of the toggle element.
 * @param {string} toggle Toggle selector
 */
const setRandomContent = (toggle = '[data-random="images"]') => {

  // Get the DOM element that contains the content to be displayed.
  const imgHolder = document.querySelectorAll(toggle);
  
  /**
   * Initial function to assign the random content to each selected 
   * element.
   * 
   * Example HTM-DOM
   * ```html
    <section>
      <div class="parent">
        <div class="image-wrap" id="image_target">
          <img src="https://via.placeholder.com/2050x1035/65B32E/43791D?Text=Digital.com" />
        </div>
        <div style="display: none;" data-random="images" data-target-id="image_target">
          <div data-shadow="true" data-is-dark="true">
            <img src="https://via.placeholder.com/2050x1035/65B32E/43791d?Text=lorberg.com" />
            <h1>Element 1</h1>
          </div>
          <div data-shadow="true" data-is-dark="true">
            <img src="https://via.placeholder.com/2050x1035/65B32E/43791d?Text=lorberg.com" />
            <h1>Element 1</h1>
          </div>
        </div>
        <div class="body">
          <header>
            <h1>Title</h1>
          </header>
          <main>
            <button>
              <span class="button__text">
                CTA
              </span>
            </button>
          </main>
        </div>
      </div>
    </section>
   * ```
   * @param {Object} element 
   */
  const applyHtml = (element) => {

    // Object containing the initial setup
    const dataset = element.dataset;

    // Get all Child nodes
    const children = element.childNodes;

    // Array containing all content elements
    const elements = [];

    // Find all '<div></div>' Elements and save them in the `elements` array.
    children.forEach(child => child.localName === 'div' && elements.push(child));

    // If nothing found then return this function.
    if (!elements || (elements && elements.length === 0)) {
      return;
    }

    // Get a random index from all exiting elements.
    const randomIndex = Math.floor(Math.random() * elements.length);
    
    // Get the current element by the random image
    const randomElement = elements[randomIndex];

    // If the current element doesn't exist return this function.
    if (!randomElement) {
      return;
    }

    // Get the config for the current element.
    const wrapDataset = randomElement.dataset;

    // Get the imageTarget element that will hold the new HTML.
    const imageTarget = dataset && dataset.targetId && document.getElementById(dataset.targetId);

    // If imageTarget wasn't found, return this function.
    if (!imageTarget) {
      return;
    }

    // Get the image of the current element.
    const image = randomElement.querySelector('img');
    
    // Get the title of the current element
    const title = randomElement.querySelector('h1');

    // If image exist replace the current HTML with the new content.
    if (image) {
      imageTarget.innerHTML = '';
      imageTarget.appendChild(image);
    }
    
    // Get the parent element of image wrap.
    const parentOfImageTarget = imageTarget.parentElement;

    // If a title exist and the parent was found replace the 
    // current HTML with the new content.
    if (parentOfImageTarget && title) {
      const heading = parentOfImageTarget.querySelector('header h1');

      if (heading && title) {
        heading.innerHTML = '';
        heading.appendChild(title);
      }
    }

    const counterCurrent = parentOfImageTarget.querySelector('[data-random="current"]');
    if (counterCurrent) {
      counterCurrent.textContent = randomIndex + 1;
    }

    const counterAll = parentOfImageTarget.querySelector('[data-random="all"');
    if (counterAll) {
      counterAll.textContent = elements.length;
    }    

    // If config exist and `wrapDataset.shadow === true` add the shadow class
    if (wrapDataset && wrapDataset.shadow) {
      imageTarget.classList.add('hero__image--shadow');
    }

    // If config exist and `wrapDataset.isDark === true` add the color white classes
    if (wrapDataset && wrapDataset.isDark) {

      // Get the header element 
      const header = document.body.querySelector('header.site-header');

      // If header exist add the white color class
      if (header) {
        header.setAttribute('data-is-white', 'true');
        header.classList.add('site-header--white');
      }

      // If parent of image target exist add the white color class
      if (parentOfImageTarget) {
        parentOfImageTarget.classList.add('color--white');
      }
    }
  };

  // Image holder exist iterate over all items and call the initial function
  if (imgHolder && imgHolder.length > 0) {
    imgHolder.forEach(applyHtml);
  }
};

$(document).ready(() => setRandomContent());