var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./blog-latest-item.hbs"),depth0,{"name":"./blog-latest-item","hash":{"item":blockParams[0][0]},"data":data,"blockParams":blockParams,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "<div class=\"grid__row\">\r\n  <div class=\"grid__col--12\">\r\n    <main class=\"blog-list__body\" data-masonry=\"grid\" role=\"list\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.blogEntries : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":4,"column":6},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    </main>\r\n    <footer class=\"blog__footer text--right\">\r\n      <a class=\"button button--space button--inline\" href=\"blog.html\">\r\n        <span class=\"button__text\">\r\n          See all posts\r\n        </span>\r\n      </a>\r\n    </footer>\r\n  </div>\r\n</div>";
},"usePartial":true,"useData":true,"useBlockParams":true});