var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "target=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1)) != null ? stack1.target : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "        <date class=\"article__date\">\r\n          "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.date : stack1), depth0))
    + "\r\n        </date>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.tags : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "          <div class=\"article__tags text--right\" role=\"list\">\r\n"
    + ((stack1 = container.invokePartial(require("./blog-tags.hbs"),depth0,{"name":"./blog-tags","hash":{"tags":((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.tags : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.tags : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "          <div class=\"article__tags\" role=\"list\">\r\n"
    + ((stack1 = container.invokePartial(require("./blog-tags.hbs"),depth0,{"name":"./blog-tags","hash":{"tags":((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.tags : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "        <a \r\n          class=\"article__header-link\" \r\n          href=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1)) != null ? stack1.href : stack1), depth0))
    + "\" \r\n          title=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1)) != null ? stack1.title : stack1), depth0))
    + "\" \r\n          "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1)) != null ? stack1.target : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":10},"end":{"line":38,"column":69}}})) != null ? stack1 : "")
    + "\"\r\n        >\r\n          "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "\r\n        </a>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {});

  return "<article class=\"blog-list__item article\" data-masonry=\"item\" role=\"listitem\">\r\n  <div class=\"article__wrap\">\r\n    <div class=\"article__image-wrap\">\r\n      <a \r\n        class=\"article__image-link\" \r\n        href=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1)) != null ? stack1.href : stack1), depth0))
    + "\" \r\n        title=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1)) != null ? stack1.title : stack1), depth0))
    + "\" \r\n        "
    + ((stack1 = helpers["if"].call(alias4,((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1)) != null ? stack1.target : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":67}}})) != null ? stack1 : "")
    + "\"\r\n      >\r\n        <img class=\"article__image image--fluid\" src=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.image : stack1)) != null ? stack1.src : stack1), depth0))
    + "\" alt=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.image : stack1)) != null ? stack1.alt : stack1), depth0))
    + "\" />\r\n      </a>\r\n    </div>\r\n    <div class=\"article__meta\">\r\n"
    + ((stack1 = helpers["if"].call(alias4,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.date : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias4,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.date : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":30,"column":17}}})) != null ? stack1 : "")
    + "    </div>\r\n    <h2 class=\"article__header\">\r\n"
    + ((stack1 = helpers["if"].call(alias4,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":42,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias4,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":45,"column":17}}})) != null ? stack1 : "")
    + "    </h2>\r\n  </div>\r\n</article>";
},"usePartial":true,"useData":true});