
const toggle = '[data-toggle="pushnav"]';

const getTargetFromTrigger= function(trigger) {
  let href;
  const target = trigger.attr('data-target') || ((href = trigger.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, ''));
  return $(target);
};

$.fn.pushnavClassToggle = function(_, state) {
  return this.each(function() {
    switch (state) {
      case 'show':
        return $(this)
          .addClass(_.options.transitions + ' ' + _.options.opening)
          .attr('aria-expanded', true);
      case 'shown':
        return $(this)
          .removeClass(_.options.transitions + ' ' + _.options.opening)
          .addClass(_.options.active);
      case 'hide':
        return $(this)
          .removeClass(_.options.active)
          .addClass(_.options.transitions + ' ' + _.options.closing)
          .attr('aria-expanded', false);
      case 'hidden':
        return $(this).removeClass(_.options.transitions + ' ' + _.options.closing);
      default:
        return this;
    }
  });
};

class Pushnav {
  static initClass() {
  
    Pushnav.VERSION = '1.0.0';
  
    Pushnav.TRANSITION_DURATION = 400;
  
    Pushnav.DEFAULTS = {
      toggle                  : true,
      active                  : 'open',
      opening                 : 'opening',
      closing                 : 'closing',
      transitions             : 'transitions',
      push                    : true,
  
      // List og elements that gets pushed
      pushElements            : 'body, [data-trigger-push="nav"]',
      pushClass               : 'push',
  
      setDimension            : false
    };
  }

  constructor(element, options) {
    // The push nav target that holds the data
    this.element = $(element);
    const data = this.element.data();
    this.options = $.extend({}, Pushnav.DEFAULTS, options, data);
    this.id = this.element.attr('id');
    this.trigger = $('[data-toggle="pushnav"][href="#' + this.id + '"],' + '[data-toggle="pushnav"][data-target="#' + this.id + '"]');
    this.pushElements = this.options.push ? $(this.options.pushElements) : undefined;
    this.transitioning = null;

    if (this.options.parent) {
      this.parent = this.getParent();
    } else {
      this.addAriaAndOpenedClass(this.element, this.trigger);
    }

    if (this.options.event && (this.options.event === 'ready')) {
      if (this.options.push && this.pushElements.length && !this.pushElements.hasClass(this.options.pushClass)) {
        this.pushElements.addClass(this.options.pushClass);
      }
    }

    if (this.options.toggle) {
      this.toggle();
    }
  }

  dimension() {
    const hasWidth = this.element.hasClass('width');
    if (hasWidth) { 
      return 'width'; 
    } else { 
      return 'height'; 
    }
  }

  show() {
    if (this.transitioning || this.element.hasClass(this.options.active)) {
      return;
    }

    let activesData;
    const actives = this.parent && this.parent.children('.panel').children('.open, .opening');

    if (actives && actives.length) {
      activesData = actives.data('bs.pushnav');
      if (activesData && activesData.transitioning) {
        return;
      }
    }

    const startEvent = $.Event('show.pushnav');
    this.element.trigger(startEvent);

    if (startEvent.isDefaultPrevented()) {
      return;
    }

    if (actives && actives.length) {
      Plugin.call(actives, 'hide');
      if (activesData) {
        actives.data('bs.pushnav', null);
      }
    }

    const dimension = this.dimension();

    const childOf = this.options.childOf && $(this.options.childOf).find(this.element) ? true : false;

    this.element.pushnavClassToggle(this, 'show');
    this.trigger.pushnavClassToggle(this, 'show');

    if (this.options.setDimension) {
      this.element[dimension](0);
    }

    if (this.options.push && this.pushElements.length && !childOf) {
      this.pushElements.pushnavClassToggle(this, 'show');
      this.pushElements.each(function(){
        const {
          pushStyle
        } = $(this).data();
        $(this).setscrollbarwidth('set',{
          css: pushStyle && (pushStyle.length > 0) ? pushStyle : 'padding-right'
        });
      });
    }
      // @pushElements.setscrollbarwidth('set', {css: 'padding-right', ignoreHeight: true})

    this.transitioning = 1;

    const complete = () => {
      this.element.pushnavClassToggle(this, 'shown');
      this.trigger.pushnavClassToggle(this, 'shown');

      if (this.options.setDimension) {
        this.element[dimension]('');
      }


      if (this.options.push && this.pushElements.length && !childOf) {
        this.pushElements.pushnavClassToggle(this, 'shown');
      }

      this.transitioning = 0;
      this.element
        .trigger('shown.pushnav');
    };
    
    console.log($.support.transition);
    if (!$.support.transition) {
      return complete.call(this);
    }

    if (this.options.setDimension) {
      const scrollSize = $.camelCase(['scroll', dimension].join('-'));
      this.element[dimension](this.element[0][scrollSize]);
    }

    

    this.element
      .one('bsTransitionEnd', $.proxy(complete, this))
      .emulateTransitionEnd(Pushnav.TRANSITION_DURATION);


  }

  hide() {
    let dimension;
    if (this.transitioning || !this.element.hasClass(this.options.active)) {
      return;
    }

    const startEvent = $.Event('hide.pushnav');
    this.element.trigger(startEvent);

    if (startEvent.isDefaultPrevented()) {
      return;
    }
    if (this.options.setDimension) {
      dimension = this.dimension();
      // eslint-disable-next-line no-unused-expressions
      this.element[dimension](this.element[dimension]())[0].offsetHeight;
    }

    const childOf = this.options.childOf && $(this.options.childOf).find(this.element) ? true : false;

    this.element.pushnavClassToggle(this, 'hide');
    this.trigger.pushnavClassToggle(this, 'hide');

    if (this.options.push && this.pushElements.length && !childOf) {
      this.pushElements.pushnavClassToggle(this, 'hide');
      this.pushElements.each(function() {
        const {
          pushStyle
        } = $(this).data();
        $(this).setscrollbarwidth('clear',{
          css: pushStyle && (pushStyle.length > 0) ? pushStyle : 'padding-right'
        });
      });
    }

      //# @pushElements.setscrollbarwidth('clear', {css: 'padding-right', ignoreHeight: true})

    this.transitioning = 1;

    const complete = () => {
      this.transitioning = 0;

      this.element.pushnavClassToggle(this, 'hidden');
      this.trigger.pushnavClassToggle(this, 'hidden');

      if (this.options.push && this.pushElements.length && !childOf) {
        this.pushElements.pushnavClassToggle(this, 'hidden');
      }

      this.element.trigger('hidden.pushnav');
    };


    if (this.options.setDimension) {
        this.element[dimension](0);
      }


    if (!$.support.transition) {
      complete.call(this);
    }

    this.element
      .one('bsTransitionEnd', $.proxy(complete, this))
      .emulateTransitionEnd(Pushnav.TRANSITION_DURATION);
  }


  toggle() {
    this.searchForPushnavChildren();
    this[this.element.hasClass(this.options.active) ? 'hide' : 'show']();
  }

  getParent() {
    $(this.options.parent)
      .find('[data-toggle="pushnav"][data-parent="' + this.options.parent + '"]')
      .each($.proxy( function(i, element) {
        element = $(element);
        this.addAriaAndOpenedClass(getTargetFromTrigger(element), element);
      }), this)
      .end();
  }

  addAriaAndOpenedClass(element, trigger) {
    const isOpen = element.hasClass(this.options.active);
    element.attr('aria-expanded', isOpen);
    trigger
      .attr('aria-expanded', isOpen);
    if (isOpen) {
      trigger.addClass(this.options.active);
    } else {
      trigger.removeClass(this.options.active);
    }
  }

  searchForPushnavChildren() {
    const children = this.element.find(toggle);
    if (children.length) {
      const childTarget = getTargetFromTrigger(children);
      return childTarget.attr('data-child-of', '#' + this.id);
    } else {
      return;
    }
  }

  checkIfElementIsChildOf() {
    const isChild = $(this.options.childOf).find(this.element);
    if (isChild.length) { return true; } else { return false; }
  }
}

// Assign pushnav to the toggle target because it holds the data for it self
const Plugin = function(option) {
  return this.each(function() {
    const $this = $(this);
    let data = $this.data('bs.pushnav');
    const options = $.extend({}, Pushnav.DEFAULTS, $this.data(), (typeof option === 'object') && option);
    if (!data && options.toggle && /show|hide/.test(option)) {
      options.toggle = false;
    }
    if (!data) {
      $this.data('bs.pushnav', (data = new Pushnav(this, options)));
    }
    if (typeof option === 'string') {
      data[option]();
    }
  });
};


Pushnav.initClass();

const old = $.fn.pushnav;

$.fn.pushnav = Plugin;
$.fn.pushnav.Constructor = Pushnav;

$.fn.pushnav.noConflict = function() {
  $.fn.pushnav = old;
  return this;
};

$(document).ready(function() {
  // Init push nav after page load
  $(toggle).each(function(){
    
    // get target of toggle
    const target = $(getTargetFromTrigger($(this)));
    
    // tell the plugin that the page is ready
    Pushnav.DEFAULTS.event= 'ready';

    // Toggle class depending on it's current state
    if (target.hasClass(Pushnav.DEFAULTS.active)) {
      return target.pushnav('show');
    } else if (!target.hasClass(Pushnav.DEFAULTS.active)) {
      return target.pushnav('hide');
    }
  });
}) 
  // Listening for click events on document and fire action if
  // closest is toggle [data-toggle="pushnav"] 
  .on('click.pushnav.data-api', toggle, function(e) {
    const $this = $(this);
    if ($this.attr('href')) {
      e.preventDefault();
    }
    const $target = getTargetFromTrigger($this);
    const data = $target.data('bs.pushnav');
    const option = data ? 'toggle' : $this.data();
    Plugin.call($target, option);
  })
  // Listening for click events on document and fire action if
  // not closest (click on background)
  .on('click.pushnav.data-api', e => $(toggle).each(function(){
    const $this = $(this);
    const target = $(getTargetFromTrigger($this));
    if (target.hasClass(Pushnav.DEFAULTS.active)) {
      const closestToggle = $(e.target).closest($this);
      const closestTarget = $(e.target).closest(target);
      if (!closestToggle.length && !closestTarget.length) {
        return target.pushnav('hide');
      }
    }
  }))
  // Listening for click events on document and fire action if
  // closest is [data-dismiss="pushnav"] aka close button.
  .on('click.dismiss.pushnav.data-api', '[data-dismiss="pushnav"]', function(e) {
    e.preventDefault();
    const target = getTargetFromTrigger($(this));
    return $(target).pushnav('hide');
  });
