require('offline-plugin/runtime').install();

// Fonts
import './fonts/poppins-v9-latin-300.woff';
import './fonts/poppins-v9-latin-300.woff2';
import './fonts/poppins-v9-latin-500.woff';
import './fonts/poppins-v9-latin-500.woff2';
import './fonts/poppins-v9-latin-600.woff';
import './fonts/poppins-v9-latin-600.woff2';
import './fonts/poppins-v9-latin-700.woff';
import './fonts/poppins-v9-latin-700.woff2';
import './fonts/poppins-v9-latin-800.woff';
import './fonts/poppins-v9-latin-800.woff2';

import './fonts/playfair-display-v15-latin-regular.woff';
import './fonts/playfair-display-v15-latin-regular.woff2';
import './fonts/playfair-display-v15-latin-700.woff';
import './fonts/playfair-display-v15-latin-700.woff2';

// Templates
import './templates/index.hbs';
import './templates/scaffold.hbs';

// Assets
import './styles/index.scss';
import './scripts/index.js';