import StickyBase from 'sticky-sidebar';
const Sticky = StickyBase;

// Hack to disable "translate" inline styles.
Sticky.prototype._getTranslate = () => false;

/**
 * This module is based on https://abouolia.github.io/sticky-sidebar/
 * 
 * @author Daniel Pfisterer
 * @copyright 2019 Ventzke Media
 * 
 * How to use it
 * ```html
  <div class="sticky__container-selector" id="container_selector">
    <div>
      Some other contents
    </div>

    <!-- START Sticky section -->
    <div class="sticky" 
      id="sticky-element" 
      data-init="sticky" 
      data-container-selector="#container_selector"
    >
      <div class="sticky__fixed">
        <div class="sticky__inner">
          Sticky container
        </div>
      </div>
    </div>
    <!-- END Sticky section -->
  </div>
 * ```
 * 
 * @param {string} toggle Query selector for sticky elements
 */
const callSticky = (toggle = '[data-init="sticky"]', options = {}) => {

  // Get all sticky elements
  const elements = document.querySelectorAll(toggle);

  if (elements && elements.length > 0) {
    
    // Iterate over all occurrent elements to init the Sticky Plugin
    elements.forEach(element => {
      const elementId = element.id && element.id.length > 0 && element.id;

      // Throw an error if id argument is missing.
      if (!elementId) {
        throw new Error('No ID present, please set an element id');
      }

      // Save data attributes
      const data = element.dataset;

      /**
       * Create options including data attributes.
       * @param {number} topSpacing Spacing to window top edge 
       * @param {number*} bottomSpacing Spacing to window bottom edge
       */
      const stickyOptions = (topSpacing = 0, bottomSpacing = 0) => Object.assign({}, {
        innerWrapperSelector: '.sticky__fixed',
        topSpacing,
        bottomSpacing,
      }, options, data);

      /** Function to create new instance of the Sticky Plugin 
       *  having spacings available as argument 
       * @param {number} topSpacing Spacing to window top edge 
       * @param {number} bottomSpacing Spacing to window bottom edge
       */
      const initSticky = (topSpacing = 0, bottomSpacing = 0) => 
        new Sticky(`#${elementId}`, stickyOptions(topSpacing, bottomSpacing)); 

      // Save the siteHeader height
      let offsetTop = 0;
      
      if (data.siteHeader) {
        const header = document.querySelector(data.siteHeader);
        offsetTop = (header && header.scrollHeight) || 0;
      }

      // Create the new instance of the sticky plugin
      let sticky = initSticky(offsetTop);
      
      // Update the Sticky plugin on tab change and if it contains any 
      // Tab content.
      const tabElements = element.querySelectorAll('[data-tab="content"');
      if (tabElements && tabElements) {        
        const tabButtons = document.querySelectorAll('.tabs__nav-item > [role="button"]');
      
        if (tabButtons && tabButtons.length > 0) {
          tabButtons.forEach(button => {
            button.addEventListener('tabs.shown', () => 
              sticky && sticky.update && sticky.update());
          });
        }
      }
    
      // Update the Sticky plugin if a window resize events happens.
      window.addEventListener('resize', () => {
        sticky.destroy();
        offsetTop = $('.site-header').outerHeight();
        sticky = initSticky(offsetTop);
        if (sticky && sticky.update) {
          sticky.update();
        }
      });
    });
  }
};

$(document).ready(() => callSticky());
