// /* eslint-disable */

var Plugin, initialize, old, trigger;

trigger = '[data-scroll]';

class ChangeOnScroll {

  static initClass() {    
    ChangeOnScroll.VERSION = '1.0.0';

    ChangeOnScroll.DEFAULTS = {
      eventName: 'changeonscroll',
      prefix: ['-webkit-', '-ms-', '-o-', ''],
      unit: null,
      factor: null,
      offset: null,
      offsetTop: null,
      offsetBottom: null,
      subtractTop: null,
      subtractBottom: null
    };
  }

  constructor(element, options) {
    // console.dir(this);
    this.element = $(element);
    this.data = this.element.data();
    
    this.options = $.extend({}, this.DEFAULTS, options, this.data);
    this.position = this.options.scrollTop;
    if (this.options.offset) {
      this.options.offsetTop = this.options.offset;
      this.options.offsetBottom = this.options.offset;
    }
    this.offset = this.getOffset();
    this.unit = this.options.unit ? this.options.unit : '';
    this.factor = this.options.factor ? this.options.factor : 1;
    this.style = this.options.style;
    this.inSide = this.isInSide();
    if (!this.element.is('.' + this.inSide)) {
      this.element.toggleClass(this.inSide);
    }

    return this;
  }

  isInSide() {
    var state;
    state = null;
    if (this.position >= this.offset.top && this.position <= this.offset.bottom && !this.options.isScrolled) {
      state = 'inside';
      this.stateToggle(state, ['before', 'after']);
      this.calculatePositionAndSetStyles();
      this.options.isScrolled = false;
    } else if (this.position >= this.offset.bottom && !this.options.isScrolled) {
      state = 'after';
      if (!this.element.is('.' + state)) {
        this.setPositionAndSetStyles('end');
      }
      this.stateToggle(state, ['inside', 'before']);
      this.options.isScrolled = true;
    } else if (this.position <= this.offset.top && !this.options.isScrolled) {
      state = 'before';
      if (!this.element.is('.' + state)) {
        this.setPositionAndSetStyles('start');
      }
      this.stateToggle(state, ['inside', 'after']);
      this.options.isScrolled = true;
    }
    return state;
  }

  stateToggle(currState, prevState) {
    if (!this.element.is('.' + currState)) {
      this.element.addClass(currState);
      this.createEventListener(currState);
    }
    if (this.element.is('.' + prevState[0])) {
      this.element.removeClass(prevState[0]);
    }
    if (this.element.is('.' + prevState[1])) {
      return this.element.removeClass(prevState[1]);
    }
  }

  calculatePercentage() {
    var result;
    result = (this.position - this.offset.top) / (this.offset.bottom - this.offset.top);
    return result.toFixed(6);
  }

  calculateRatio(start, end) {
    var ratio, result;
    result = null;
    ratio = this.calculatePercentage() * (start - end) * this.factor;
    if (this.options.reverse) {
      result = Number(start) + ratio;
      return result;
    } else {
      result = Number(start) - ratio;
      return result;
    }
  }

  setPositionAndSetStyles(state) {
    var error, fixed, inner, separator, style, styles, styleString, temp, unit, val, valLength, value, values;
    styles = {};
    for (style in this.style) {
      if (style === 'transform' || style.indexOf('color') !== -1) {
        styleString = style === 'color' ? null : '';
        for (inner in this.style[style]) {
          if (inner) {
            unit = this.style[style][inner].unit ? this.style[style][inner].unit : '';
            if (style.indexOf('color') !== -1) {
              styleString = [this.style[style][inner][state]];
            } else {
              if (typeof this.style[style][inner][state] === 'object') {
                value = '';
                values = this.style[style][inner][state];
                valLength = this.style[style][inner][state].length - 1;
                for (val in values) {
                  if (val) {
                    separator = val < valLength ? ',' : '';
                    value += values[val] + unit + separator;
                  }
                }
                styleString += inner + '(' + value + ') ';
              } else {
                styleString = inner + '(' + this.style[style][inner][state] + unit + ') ';
              }
            }
          }
        }
        if (style === 'transform') {
          for (fixed in this.options.prefix) {
            if (fixed) {
              temp = this.options.prefix[fixed] + style;
              styles[temp] = styleString;
            }
          }
        } else if (style.indexOf('color') !== -1) {
          try {
            styles[style] = $.Color(styleString[0]);
          } catch (error1) {
            error = error1;
            console.warn('You want to use $.Color as "' + style + '", it doesnt work. May be you forgot to implement it. Go to https://github.com/jquery/jquery-color to get more Informations');
            console.error(error);
            return false;
          }
        }
      } else {
        unit = this.style[style].unit ? this.style[style].unit : '';
        styles[style] = this.style[style][state] + unit;
      }
    }

    console.log('setPositionAndSetStyles: ', styles);
    
    return this.element.css(styles);
  }

  calculatePositionAndSetStyles() {
    var fixed, inner, style, styles, styleString, temp, unit;
    styles = {};
    for (style in this.style) {
      if (style === 'transform' || style.indexOf('color') !== -1) {
        styleString = style === 'color' ? null : '';
        for (inner in this.style[style]) {
          if (inner || inner === 0) {
            unit = this.style[style][inner].unit ? this.style[style][inner].unit : '';
            if (style.indexOf('color') !== -1) {
              styleString = this.calculateStyles(this.style[style], inner);
            } else {
              styleString += inner + '(' + this.calculateStyles(this.style[style], inner) + ') ';
            }
          }
        }
        if (style === 'transform') {
          for (fixed in this.options.prefix) {
            if (fixed) {
              temp = this.options.prefix[fixed] + style;
              styles[temp] = styleString;
            }
          }
        } else if (style.indexOf('color') !== -1) {
          styles[style] = $.Color(styleString);
        }
      } else {
        styles[style] = this.calculateStyles(this.style, style);
      }
    }
    console.log('calculatePositionAndSetStyles: ', styles);
    console.log(this.element);
    
    return this.element.css(styles);
  }

  calculateStyles(style, key) {
    var calculateObject, end, start, unit;
    start = style[key].start;
    end = style[key].end;
    unit = style[key].unit ? style[key].unit : '';
    if (typeof start === 'object' || typeof end === 'object') {
      if (typeof start !== 'object') {
        console.warn('The property of end in "' + key + '" is set as array, start also must be an array');
        return false;
      }
      if (typeof end !== 'object') {
        console.warn('The property of start in "' + key + '" is set as array, end also must be an array');
        return false;
      }
      calculateObject = (function(_this) {
        return function(s, e) {
          var length, obj, object, separator;
          object = key === 'rgb' || key === 'rgba' ? [] : '';
          length = s.length - 1;
          for (obj in s) {
            if (!e[obj] && typeof e[obj] !== 'number') {
              console.warn('The length of start and end in "' + key + '" is not equal, but they have to be');
              return false;
            } else {
              separator = obj < length ? ',' : '';
              if (key === 'rgb' || key === 'rgba') {
                object.push(_this.calculateRatio(s[obj], e[obj]));
              } else {
                object += _this.calculateRatio(s[obj], e[obj]) + unit + separator;
              }
            }
          }
          return object;
        };
      }(this));
      return calculateObject(start, end);
    } else {
      return this.calculateRatio(start, end) + unit;
    }
  }

  getOffset() {
    var height, offset, subtract;
    offset = {};
    subtract = this.getSubtract();
    if (this.options.offsetTop) {
      if (typeof this.options.offsetTop === 'number') {
        offset.top = this.options.offsetTop + subtract.top;
      } else if (typeof this.options.offsetTop === 'object') {
        offset.top = this.options.offsetTop.offset().top + subtract.top;
      } else {
        offset.top = $(this.options.offsetTop).length ? $(this.options.offsetTop).offset().top + subtract.top : 0;
      }
    } else {
      offset.top = this.element.offset().top + subtract.top;
    }
    if (this.options.offsetBottom) {
      if (typeof this.options.offsetBottom === 'number') {
        offset.bottom = this.options.offsetBottom - subtract.bottom;
      } else if (typeof this.options.offsetBottom === 'object') {
        height = this.options.offset ? this.options.offsetBottom.outerHeight() : 0;
        offset.bottom = this.options.offsetBottom.offset().top + height - subtract.bottom;
      } else {
        height = this.options.offset ? $(this.options.offsetBottom).outerHeight() : 0;
        offset.bottom = $(this.options.offsetBottom).length ? $(this.options.offsetBottom).offset().top + height - subtract.bottom : 0;
      }
    } else {
      offset.bottom = this.element.offset().top + this.element.outerHeight() - subtract.bottom;
    }
    return offset;
  }

  getSubtract() {
    var subtract;
    subtract = {};
    if (this.options.subtractTop === 'this') {
      this.options.subtractTop = this.element;
    }
    if (this.options.subtractBottom === 'this') {
      this.options.subtractBottom = this.element;
    }
    if (this.options.subtractTop) {
      if (typeof this.options.subtractTop === 'number') {
        subtract.top = this.options.subtractTop;
      } else if (typeof this.options.subtractTop === 'object') {
        subtract.top = this.options.subtractTop.outerHeight();
      } else {
        subtract.top = $(this.options.subtractTop).outerHeight();
      }
    } else {
      subtract.top = 0;
    }
    if (this.options.subtractBottom) {
      if (typeof this.options.subtractBottom === 'number') {
        subtract.bottom = this.options.subtractBottom;
      } else if (typeof this.options.subtractBottom === 'object') {
        subtract.bottom = this.options.subtractBottom.outerHeight();
      } else {
        subtract.bottom = $(this.options.subtractBottom).outerHeight();
      }
    } else {
      subtract.bottom = 0;
    }
    return subtract;
  }

  createEventListener(name) {
    var event, eventType;
    eventType = name;
    event = $.Event(eventType + '.' + this.options.eventName);
    event[this.options.eventName] = {
      offset: this.offset,
      position: this.position,
      options: this.options
    };
    this.element.trigger(event);
  }
}


initialize = function(element, option) {
  option.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  
  return element.each(function() {
    var $this, options, run;
    $this = $(this);
    run = false;
    if ($this.data('mobile') === false && $(window).width() <= 1024) {
      run = false;
    } else {
      run = true;
    }
    if (run === true) {
      options = $.extend({}, ChangeOnScroll.DEFAULTS, option);
      new ChangeOnScroll($this, options);
    }
  });
};

Plugin = function(option) {
  var element;
  element = this;
  element.data('isScrolled', false);
  initialize(element, option);
  return $(window).on('scroll', false, function() {
    return initialize(element, option);
  });
};

ChangeOnScroll.initClass();

old = $.fn.changeonscroll;

$.fn.changeonscroll = Plugin;

$.fn.changeonscroll.Constructor = ChangeOnScroll;

$.fn.changeonscroll.noConflict = function() {
  $.fn.changeonscroll = old;
  return this;
};

$(document).ready(e => {
  var element, options;
  element = $(trigger);

  if (element.length) {
    options = $.extend({}, ChangeOnScroll.DEFAULTS);
    return Plugin.call(element, options);
  }
});
