
const blog = require('../../src/data/pages/blog');
const blogDetail = require('../../src/data/pages/blog-detail');
const cases = require('../../src/data/pages/cases');
const index = require('../../src/data/pages/index');

module.exports = (key, options) => {
  const pages = {
    blog,
    'blog-detail': blogDetail,
    cases,
    index,
  };

  return options.fn({page: pages[key] || null});
};